<template>
  <div class="kc_upload">
    <Header
      back="返回"
      title="知识产权"
      index="首页"
      titleOne="科创模块"
      titleTwo="知识产权"
      titleThree="专利列表"
      beforeTitle="快捷上传"
    />
    <div class="content">
      <div class="topUsers">
        <el-input
          v-model="input"
          placeholder="请输入想要上传附件的企业名称查询"
        ></el-input>
        <el-button class="search">查询</el-button>
      </div>
      <h1>快捷上传说明</h1>
      <h2>
        请在上方搜索框内进行查询，然后依次上传对应的附件即可。<span
          >（上传文件格式支持pdf，jpg，word，压缩包zip/rar）</span
        >
      </h2>
      <el-table
        :data="tableData"
        style="width: 100%"
        v-table-infinite-scroll="load"
        max-height="480"
      >
        <el-table-column prop="face" label="专利名称" width="600">
        </el-table-column>
        <el-table-column prop="name" label="专利名称" width="150">
        </el-table-column>
        <el-table-column prop="operation" label="操作" align="right">
          <template slot-scope="scope">
            <el-button
              class="edit"
              size="mini"
              @click="handleEdit(scope.$index, scope.row)"
              >上传</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
          state: "启用",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
          state: "禁用",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
          state: "禁用",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
          state: "禁用",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
          state: "启用",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
          state: "禁用",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
      options: [
        {
          value: "选项1",
          label: "合作客户",
        },
        {
          value: "选项2",
          label: "意向客户",
        },
        {
          value: "选项3",
          label: "专利",
        },
        {
          value: "选项4",
          label: "软著",
        },
      ],
      value: "",
    };
  },
  methods: {
    load() {
      this.count += 2;
    },
  },
  directives: {
    tableInfiniteScroll: {
      bind(el, binding) {
        let tbody = el.querySelector(".el-table__body-wrapper");
        el.tableInfiniteScrollFn = function () {
          if (this.scrollHeight - this.scrollTop - this.client == 0) {
            binding.value();
          }
        };
        tbody.addEventListener("scroll", el.tableInfiniteScrollFn);
        tbody = undefined;
      },
      unbind(el) {
        let tbody = el.querySelector(".el-table__body-wrapper");
        tbody.removeEventListener("scorll", el.tableInfiniteScrollFn);
        el.tableInfiniteScrollFn = undefined;
      },
    },
  },
};
</script>

<style scoped="scoped">
.topUsers {
  border-bottom: 1px solid #edeef4;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.content h1 {
  font-size: 20px;
  color: #000000;
}

.content h2 {
  color: #000000;
  font-size: 14px;
  margin-top: 20px;
  font-weight: 400;
}
.content h2 span {
  color: #a6abc7;
}

.content a {
  font-weight: bold;
  color: #0f38ff;
}

.Importcenter .content h2 span {
  color: #a6abc7;
}

.content dl {
  margin-top: 40px;
  height: 400px;
}

.content dl dt {
  border-bottom: 1px solid #ececec;
  padding-bottom: 20px;
  margin-bottom: 20px;

  color: #bdbdbd;
}

.content dl dd {
  border-bottom: 1px solid #ececec;
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-left: 0;
}

.content dl dt span {
  width: 33.33%;
  display: inline-block;
  margin-right: 50px;
}

.content dl dd span {
  width: 33.33%;
  display: inline-block;
  margin-right: 50px;
}
</style>
